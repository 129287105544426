import { callExternalApi } from "./external-api.service";

const apiServerUrl = 'http://localhost:6060';

export const getWorkouts = async(page) =>{
  const response = await fetch(`${apiServerUrl}/api/workout/all/paginated/`+page);
  return response
};

export const getTypes = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/workout/types/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, typeserror } = await callExternalApi({ config });
  return {
    types: data.result || null,
    typeserror,
  };
};

export const getCategories = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/workout/categories/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, categorieserror } = await callExternalApi({ config });
  return {
    categories: data.result || null,
    categorieserror,
  };
};

export const postWorkout = async (accessToken,formData) => {
  console.log(formData);
  const config = {
    url: `${apiServerUrl}/api/workout/add`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};

export const getWorkout = async (id, accessToken) => {
  
  const config = {
    url: `${apiServerUrl}/api/workout/`+id,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
};
  
